const injectBitrixScript = () => {
  function addJS(jsCode) {
    var s = document.createElement("script")

    s.type = `text/javascript`
    s.id = `bx24_form_inline`
    s.innerText = jsCode

    document.getElementById(`formularioContato`).appendChild(s)
  }

  addJS(`
  (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
      (w[b].forms=w[b].forms||[]).push(arguments[0])};
      if(w[b]['forms']) return;
      var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
      var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
  })(window,document,'https://sh2.bitrix24.com.br/bitrix/js/crm/form_loader.js','b24form');

  b24form({"id":"7","lang":"br","sec":"3u0bgs","type":"inline"});
  `)
}

let injectedBitrixScript = false

const embedClasses = [`#formularioContato`].join(`,`)

exports.onRouteUpdate = () => {
  if (document.querySelector(embedClasses) !== null) {
    if (!injectedBitrixScript) {
      injectBitrixScript()
      injectedBitrixScript = true
    }
  }
}
